import CryptoJS from "crypto-js";

// Function to generate a new cryptographic key
function generateCryptoKey() {
  return CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex);
}

// Function to encrypt data
function encryptData(key, data) {
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(key), {
    iv: iv,
  });
  return {
    iv: iv.toString(CryptoJS.enc.Base64),
    data: encrypted.toString(),
  };
}

// Function to decrypt data
function decryptData(key, iv, data) {
  const decrypted = CryptoJS.AES.decrypt(data, CryptoJS.enc.Hex.parse(key), {
    iv: CryptoJS.enc.Base64.parse(iv),
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

// Function to store encrypted data in local storage
export async function setEncryptedItem(key, value) {
  const rawKey = generateCryptoKey();
  const encrypted = encryptData(rawKey, value);

  localStorage.setItem(
    key,
    JSON.stringify({
      iv: encrypted.iv,
      data: encrypted.data,
      metaInfo: rawKey,
    })
  );
}

// Function to retrieve decrypted data from local storage
export async function getEncryptedItem(key) {
  const item = localStorage.getItem(key);
  if (!item) return null;

  const { iv, data, metaInfo } = JSON.parse(item);
  const decrypted = decryptData(metaInfo, iv, data);

  return decrypted;
}
