import { defineStore } from "pinia";
export const useStore = defineStore("main", {
  state: () => ({
    displayed: false,
    showError: false,
    showAccordion: false,
    errorMessage: "",
    errorAccordionMessage: "",
    errorHeader: "",
    todaysPassword: "",
    error403Handled: false,
    weekPassword: "",
    profileImage: localStorage.getItem("profileImage") || "",
  }),
  actions: {
    hide() {
      this.displayed = !this.displayed;
    },
    handleMessages(
      showAccordion,
      errorMessage,
      errorAccordionMessage,
      errorHeader
    ) {
      if (this.error403Handled == false) {
        this.showAccordion = showAccordion;
        this.errorMessage = errorMessage;
        this.errorAccordionMessage = errorAccordionMessage;
        this.errorHeader = errorHeader;
      }
    },
    errorControl(value) {
      if (this.error403Handled == false) {
        this.showError = value;
      }
    },
    handleError403(value) {
      this.error403Handled = value;
    },
    handleSupportPassword(password) {
      this.todaysPassword = password;
    },
    handleSupportWeekPassword(password) {
      this.weekPassword = password;
    },
    setProfileImage(imageUrl) {
      this.profileImage = imageUrl;
      localStorage.setItem("profileImage", imageUrl);
    },
  },
});
