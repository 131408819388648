import { getEncryptedItem } from "@/utils/encryptedStorage";

export default {
  install(app) {
    app.config.globalProperties.$permissions = {
      userPermissions: {},
      // Method to load permissions from sessionStorage
      async loadPermissions() {
        const permissionsAvailable = !!getEncryptedItem(
          "backoffice_permissions"
        );
        if (permissionsAvailable) {
          const permissions = await getEncryptedItem("backoffice_permissions");
          if (permissions) {
            this.userPermissions = JSON.parse(permissions);
          } else {
            localStorage.clear();
            ({ path: "/login", query: { sessionExpired: true } });
          }
        }
      },
      // Method to check if the user has a specific permission
      hasPermission(permission) {
        const permissionsAvailable = !!getEncryptedItem(
          "backoffice_permissions"
        );
        if (permissionsAvailable) {
          if (Object.keys(this.userPermissions).length !== 0) {
            return this.userPermissions[permission] !== undefined
              ? this.userPermissions[permission]
              : false;
          } else {
            return false;
          }
        } else {
          localStorage.clear();
          ({ path: "/login", query: { sessionExpired: true } });
          return false;
        }
      },
    };

    app.config.globalProperties.$permissions.loadPermissions();
  },
};
