export default {
  install(app) {
    app.config.globalProperties.$tabPermissions = {
      setActiveTabBasedOnPermissions(routeParams, tabs) {
        const activeTabFromRoute = parseInt(routeParams.activeTab, 10);

        // Check if the route parameter is a valid number and if the user has permission for the corresponding tab
        const validTab = tabs.find(
          (tab) =>
            tab.index === activeTabFromRoute &&
            app.config.globalProperties.$permissions.hasPermission(
              tab.permission
            )
        );

        if (validTab) {
          // If the route parameter is valid and the user has permission for the corresponding tab, set the active tab
          return activeTabFromRoute;
        } else {
          // If the route parameter is not valid or the user does not have permission, set the first permitted tab
          for (const tab of tabs) {
            if (
              app.config.globalProperties.$permissions.hasPermission(
                tab.permission
              )
            ) {
              return tab.index;
            }
          }
        }

        return tabs[0].index; // Default to the first tab if no permissions are granted
      },
    };
  },
};
